window.addEventListener("load", () => {
    document.body.classList.add("is-loaded");
  });

  /***********************************************
   * Const
   * ********************************************/
   window.addEventListener("load", () => {
    const html = document.documentElement;
    const body = document.body;
    const windowHeight = window.innerHeight;
    //   // ハンバーガーボタン
    const hamburgerButton = document.getElementById('js-hamburgerButton');

    //  // ハンバーガーメニュー
    const header = document.getElementById('js-header');
    const headerNav = document.getElementById('js-headerNav');

    // ------------------------ ハンバーガーメニュー
  const openHeaderNav = () => {
    hamburgerButton.addEventListener("click", () => {
      const headerNavOpened = body.classList.contains("is-navOpen");
      if (headerNavOpened) {
        body.classList.remove("is-navOpen");
        html.style.overflow = "";
      } else {
        body.classList.add("is-navOpen");
        html.style.overflow = "hidden";
      }
    });
  };
  if (hamburgerButton) {
    openHeaderNav();
  }

  //ハンバーガーメニュー内をクリックすると閉じる is-navOpenを消す
  const closeHeaderNav = () => {
    headerNav.addEventListener('click', () => {
      const headerNavOpened = body.classList.contains('is-navOpen');
      if (headerNavOpened) {
        body.classList.remove('is-navOpen');
        html.style.overflow = '';
      }
    });
  }
  if (headerNav) {
    closeHeaderNav();
  }
  });



  // inview
  /**
   * @function HTMLElement.prototype.inview　HTML要素と画面の交差を判定し処理を実行する
   */
  if (!HTMLElement.prototype.inview) {
    Object.defineProperty(HTMLElement.prototype, "inview", {
      configurable: true,
      enumerable: false,
      writable: true,
      /**
       * @function callbackInView  HTML要素が画面内に入った時に実行する関数
       * @function callbackOutView  HTML要素が画面から出た時に実行する関数
       */
      value: function (callbackInView, callbackOutView) {
        const options = {
          root: null,
          rootMargin: "0%", //要素が交差する手前でコールバックを呼び出したい場合はrootMarginに0%以外の値を
          threshold: [ 0.5 ], //交差領域が50%変化した時にコールバックを呼び出す
        };
        const observer = new IntersectionObserver(function (entries) {
          entries.forEach(function (e) {
            if (
              e.isIntersecting &&
              Object.prototype.toString.call(callbackInView) ===
              "[object Function]"
            ) {
              callbackInView(e);
            }
            //要素が画面から出た時
            else if (
              !e.isIntersecting &&
              Object.prototype.toString.call(callbackOutView) ===
              "[object Function]"
            ) {
              callbackOutView(e);
            }
          });
        }, options);
        observer.observe(this);
      },
    });
  }
  window.addEventListener("load", function () {
    const item = document.querySelectorAll(".iv");
    for (let i = 0; i < item.length; i++) {
      const elem = item[ i ];
      //要素が画面内に入った時クラスを付与
      setTimeout(function () {
        elem.inview(function () {
          elem.classList.add("view");
        });
      }, 800);
    }
  });

  const item = document.querySelectorAll(".iv");
  for (let i = 0; i < item.length; i++) {
    const elem = item[ i ];
    //要素が画面内に入った時クラスを付与
    elem.inview(function () {
      elem.classList.add("view");
    });
  }


  //ページ内のimg要素サイズを取得
  const myFunc = function (src) {
    return new Promise(function (resolve, reject) {
      const image = new Image();
      image.src = src;
      image.onload = function () {
        resolve(image);
      }
      image.onerror = function (error) {
        reject(error);
      }
    });
  }
  const imgs = document.getElementsByTagName('img');
  for (const img of imgs) {
    const src = img.getAttribute('src');
    myFunc(src)
      .then(function (res) {
        if (!img.hasAttribute('width')) {
          img.setAttribute('width', res.width);
        }
        if (!img.hasAttribute('height')) {
          img.setAttribute('height', res.height);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  window.addEventListener("load", () => {
    // ---------- 都道府県セレクトボックス ----------
  const prefecturesSelect = document.getElementById("js-prefectures");
  const prefecturesValue = [
    "",
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];
  const prefecturesText = [
    "選択してください",
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];

  if (prefecturesSelect) {
    for (let i = 0; i < prefecturesValue.length; ++i) {
      let prefectureValue = prefecturesValue[i];
      let prefectureText = prefecturesText[i];
      let prefectureOption = document.createElement("option");
      prefectureOption.setAttribute("value", prefectureValue);
      prefectureOption.innerHTML = prefectureText;
      prefecturesSelect.appendChild(prefectureOption);
    }
  }
  })


  window.addEventListener("load", () => {
    if(document.querySelector('.c-form')) {
      const signUpForm = document.getElementById('js-signUpForm');
      if (signUpForm) {
        const termsConfirmed = signUpForm.querySelector('.js-termsConfirmation').checked;
        if (!termsConfirmed) {
          document.getElementById('js-formSubmit').classList.add('is-disabled');
        }
        signUpForm.querySelector('.js-termsConfirmation').addEventListener('change', () => {
          const termsConfirmed = signUpForm.querySelector('.js-termsConfirmation').checked;
          if (termsConfirmed) {
            document.getElementById('js-formSubmit').classList.remove('is-disabled');
          } else {
            document.getElementById('js-formSubmit').classList.add('is-disabled');
          }
        })
      }

      const validateRadioButtonAreas = document.querySelectorAll('.js-validateRadio');
      const inputValidateTargets = document.querySelectorAll('.js-validateInput');
      const spamElements = document.getElementsByClassName('js-spam');
      let firstErrorElement;
  
      // バリデーション関数
      const VALIDATION_PATTERNS = {
        phone: /^0\d{1,4}-\d{1,4}-\d{4}$/,
        fax: /^0\d{1,4}-\d{1,4}-\d{4}$/,
        email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
        postal: /^\d{3}-\d{4}$/,
        kana: /^[\u30A0-\u30FF\u31F0-\u31FF\u3099-\u309C\u30FC]*$/
      };
  
      // エラーメッセージの定義
      const ERROR_MESSAGES = {
        phone: '電話番号の形式が正しくありません（例: 123-4567-8910）',
        fax: 'FAX番号の形式が正しくありません（例: 123-4567-8910）',
        email: 'メールアドレスの形式が正しくありません',
        password: 'パスワードは半角英数字6文字以上で入力してください',
        passwordCheck: 'パスワードが一致しません',
        postal: '郵便番号の形式が正しくありません（例: 123-4567）',
        kana: 'カタカナで入力してください',
        empty: '必須項目です'
      };
  
      // バリデーション関数
      const validateInput = (value, type) => VALIDATION_PATTERNS[type].test(value);
  
      // エラー処理関数
      const handleError = (element, hasError, errorType) => {
        const parent = element.closest('.c-tableData__box') || element.closest('.c-tableData');
        parent.classList.toggle('is-error', hasError);
  
        const errorElement = parent.querySelector('.p-validateError');
        if (errorElement) {
          if (hasError) {
            if (!errorElement.dataset.originalText) {
              errorElement.dataset.originalText = errorElement.textContent;
            }
            errorElement.textContent = ERROR_MESSAGES[errorType];
            if (!firstErrorElement) {
              firstErrorElement = parent;
            }
          } else {
            errorElement.textContent = errorElement.dataset.originalText || '';
          }
        }
      };
  
      // inputからフォーカスが外れた時のバリデーション処理
      // システム全体に工数が嵩むため一旦コメントアウト
      // if (inputValidateTargets) {
      //   inputValidateTargets.forEach(validateTarget => {
      //     validateTarget.addEventListener('blur', function() {
      //       const value = this.value.trim();
      //       let isValid = value !== '';
      //       let errorType = 'empty';
  
      //       if (isValid) {
      //         if (this.classList.contains('js-validateTel')) {
      //           isValid = validateInput(value, 'phone');
      //           errorType = 'phone';
      //         } else if (this.classList.contains('js-validateEmail')) {
      //           isValid = validateInput(value, 'email');
      //           errorType = 'email';
      //         } else if (this.classList.contains('js-validatePass')) {
      //           isValid = validateInput(value, 'password');
      //           errorType = 'password';
      //         } else if (this.classList.contains('js-validatePostal')) {
      //           isValid = validateInput(value, 'postal');
      //           errorType = 'postal';
      //         }
      //       }
  
      //       handleError(this, !isValid, errorType);
      //     });
      //   });
      // }
  
      //カーソルを外した時の処理
      if(spamElements) {
        for (let i = 0; i < spamElements.length; ++i) {
          let spamElement = spamElements[i];
          spamElement.addEventListener('blur', function() {
            let mainText = spamElement.closest('.c-tableData').querySelector('.c-tableData__main').textContent;
  
            if (spamElement.value === mainText) {
              let parentElement = spamElement.closest('.c-tableData__box');
              parentElement.classList.remove('is-error');
            } else {
              let parentElement = spamElement.closest('.c-tableData__box');
              parentElement.classList.add('is-error');
            }
          })
        }
      }
      const dateField = document.querySelector('.js-birth-date');
      const dateError = document.querySelector('.js-date-error');

      if (dateField) {
        dateField.addEventListener('input', function(e) {
          const input = e.target.value;

          // 数字以外の文字を削除
          const cleanedInput = input.replace(/\D/g, '');

          // 入力を8桁に制限
          if (cleanedInput.length > 8) {
            e.target.value = cleanedInput.slice(0, 8);
            return;
          }

          // 入力が8桁未満の場合は、そのまま表示
          if (cleanedInput.length < 8) {
            e.target.value = cleanedInput;
            dateError.style.display = 'none';
            dateField.setCustomValidity('');
            return;
          }

          // 8桁の場合、日付の妥当性をチェック
          const dateString = cleanedInput.slice(0, 4) + "-" + cleanedInput.slice(4, 6) + "-" + cleanedInput.slice(6, 8);
          const day = new Date(dateString);

          if (Number.isNaN(day.getTime()) || day.getFullYear() != cleanedInput.slice(0, 4)) {
            dateError.style.display = 'block';
            dateField.setCustomValidity('Invalid date');
          } else {
            dateError.style.display = 'none';
            dateField.setCustomValidity('');
            // 日付を YYYY-MM-DD 形式に変換
            e.target.value = dateString;
          }
        });
      }
    document.getElementById('js-formSubmit').addEventListener('click', function(event) {
      event.preventDefault();

      if (signUpForm) {
        const termsConfirmed = signUpForm.querySelector('.js-termsConfirmation').checked;
        if (termsConfirmed) {
          document.getElementById('js-formSubmit').classList.remove('is-disabled');
        }

        const dateInput = dateField.value;
        if ((dateInput.length && dateInput.length !== 10) || (dateInput.length && Number.isNaN(new Date(dateInput).getTime()))) {
          event.preventDefault();
          dateError.style.display = 'block';
        }
      }

      let form = document.querySelector('.c-form');
      let inputs = form.querySelectorAll('input[required], textarea[required], select[required]'); // 必須項目のinputとselect要素を取得
      firstErrorElement = null;
      // 必須項目のチェック
      for (let i = 0; i < inputs.length; i++) {
        let input = inputs[i];
        let parent = input.closest('.c-tableData'); // 親要素の.c-tableDataを取得
        let parent02 = input.closest('.c-tableData__box');
  
        if (input.type === 'radio') {
          // ラジオボタンの場合、同じname属性の要素で1つ以上が選択されているかチェック
          let radioGroup = form.querySelectorAll('input[type="radio"][name="' + input.name + '"]');
          let isChecked = false;

          for (let j = 0; j < radioGroup.length; j++) {
            if (radioGroup[j].checked) {
              isChecked = true;
              break;
            }
          }

          if (!isChecked) {
            // ラジオボタンが選択されていない場合
            parent.classList.add('is-error');

            if (!firstErrorElement) {
              firstErrorElement = parent;
            }
          } else {
            // ラジオボタンが正しく選択されている場合、エラークラスを削除
            for (let k = 0; k < radioGroup.length; k++) {
              let radioParent = radioGroup[k].closest('.c-tableData');
              radioParent.classList.remove('is-error');
            }
          }
        } else {
          // テキスト入力やセレクトボックスなどの場合
          let inputIsValid = input.value !== '';
          let inputErrorType = 'empty';

          if (inputIsValid) {
            if (input.classList.contains('js-validateTel')) {
              inputIsValid = validateInput(input.value, 'phone');
              inputErrorType = 'phone';
            } else if (input.classList.contains('js-validateEmail')) {
              inputIsValid = validateInput(input.value, 'email');
              inputErrorType = 'email';
            } else if (input.classList.contains('js-validatePass')) {
              inputIsValid = validateInput(input.value, 'password');
              inputErrorType = 'password';
            } else if (input.classList.contains('js-validatePostal')) {
              inputIsValid = validateInput(input.value, 'postal');
              inputErrorType = 'postal';
            } else if (input.classList.contains('js-validateFax')) {
              inputIsValid = validateInput(input.value, 'fax');
              inputErrorType = 'fax';
            } else if (input.classList.contains('js-validateKana')) {
              inputIsValid = validateInput(input.value, 'kana');
              inputErrorType = 'kana';
            }
          }

          handleError(input, !inputIsValid, inputErrorType);

          // if (!input.value) {
          //   // 必須項目が未入力または未選択の場合

          //   if(parent02) {
          //     parent02.classList.add('is-error');
          //   } else {
          //     parent.classList.add('is-error');
          //   }

          //   if (!firstErrorElement) {
          //     firstErrorElement = parent;
          //   }
          // } else {
          //   // 必須項目が正しく入力されている場合、エラークラスを削除
          //   parent.classList.remove('is-error');
          // }
        }
      }

      // 都道府県選択欄のエラーチェック
      let selectElement = document.getElementById('js-prefectures');
      if (selectElement) {
        let parentElement = selectElement.closest('.c-tableData__box');

        if (selectElement.value !== '') {
          parentElement.classList.remove('is-error');
        } else {
          parentElement.classList.add('is-error');
        }

        selectElement.addEventListener('change', function() {
          if (this.value !== '') {
            parentElement.classList.remove('is-error');
          } else {
            parentElement.classList.add('is-error');
          }
        });
      }

      if(validateRadioButtonAreas) {
        for (let i = 0; i < validateRadioButtonAreas.length; ++i) {
          let validateRadioButtonArea = validateRadioButtonAreas[i];
          validateRadioButtonArea.addEventListener('change', function() {
            validateRadioButtonArea.classList.remove('is-error');
          });
        }
      }

      // js-spam のテキストと c-tableData__main のテキストが一致しているかチェックする
      let pRegister = document.querySelector('.p-register');
      if(!pRegister){
        for (let i = 0; i < spamElements.length; i++) {
          let spamElement = spamElements[i];
          let mainText = spamElement.closest('.c-tableData').querySelector('.c-tableData__main').textContent;

          if (spamElement.value === mainText) {
            // 一致している場合の処理
            let parentElement = spamElement.closest('.c-tableData__box');
            parentElement.classList.remove('is-error');
          } else {
            // 一致していない場合の処理
            let parentElement = spamElement.closest('.c-tableData__box');
            parentElement.classList.add('is-error');

          }
        }

      } else {
        let mainPassElement = document.querySelector('.js-mainPass');
        let passElement = document.querySelector('.js-pass');
        let target = document.querySelector(".target");
        if (mainPassElement && passElement) {
          let mainPass = mainPassElement.value;
          let pass = passElement.value;

          if (mainPass !== pass) {
            // 一致していない場合の処理
            target.classList.add('is-error');
            const errorElement = target.querySelector('.p-validateError');
            errorElement.textContent = ERROR_MESSAGES.passwordCheck;
            // firstErrorElementを更新
            if (!firstErrorElement) {
              firstErrorElement = target;
            }
          }
        }

        //カーソルを外した時の処理
        passElement?.addEventListener("blur", function () {
          let mainPass = mainPassElement.value;
          let pass = passElement.value;
          if (mainPass === pass) {
            target.classList.remove("is-error");
          } else {
            target.classList.add("is-error");
          }
        });
      }
  
      for (let i = 0; i < spamElements.length; i++) {
        let spamElement = spamElements[i];
        let mainText = spamElement.closest('.c-tableData').querySelector('.c-tableData__main').textContent;
  
        if (spamElement.value === mainText) {
          // 一致している場合の処理
          let parentElement = spamElement.closest('.c-tableData__box');
          parentElement.classList.remove('is-error');
        } else {
          // 一致していない場合の処理
          let parentElement = spamElement.closest('.c-tableData__box');
          parentElement.classList.add('is-error');
          if (!firstErrorElement) {
            firstErrorElement = parentElement;
          }
        }
      }
      // エラーメッセージの表示
      if (firstErrorElement) {
        alert('必須項目の空欄または入力エラーがあります');
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        // エラーがない場合はフォームを送信
        form.submit();
      }
    });
    }
  });

  window.addEventListener("load", () => {
    // ajaxzip3 郵便番号検索
    const contactForm = document.getElementById("js-contactForm");
    const signUpForm = document.getElementById("js-signUpForm");
    const postalButton = document.querySelector(".postal-button");
    const formAddr = document.querySelector(".p-form__address");
    const selectElement = document.getElementById("js-prefectures");
    const parentElement = selectElement?.closest('.c-tableData__box');
  
    // 住所検索ボタン
    if (postalButton) {
      postalButton.addEventListener("click", function () {
        if (contactForm) {
          AjaxZip3.zip2addr("contact_form[postal]", "", "contact_form[prefecture]", "contact_form[address]");
        } else if (signUpForm) {
          AjaxZip3.zip2addr("user[postal_code]", "", "user[address]", "user[address]");
        }

        if (selectElement) {
          setTimeout(function () {
            if (selectElement.value !== '') {
              parentElement.classList.remove('is-error');
            } else {
              parentElement.classList.add('is-error');
            }
          }, 800);
        }

        AjaxZip3.onSuccess = function () {
          formAddr.classList.remove("post-error");
        };
        AjaxZip3.onFailure = function () {
          formAddr.classList.add("post-error");
        };
      });
    }
  });

  //修正するボタン
  document.addEventListener("DOMContentLoaded", function() {
    const formEditButton = document.getElementById("js-formEdit");

    if (formEditButton) {
      formEditButton.addEventListener("click", function() {
        history.back();
      });
    }
  });

  document.addEventListener('DOMContentLoaded', function() {
    if(document.getElementById('form')) {
    let inputs = document.querySelectorAll('.c-tableData__flex input[name="tel"]');

    // inputのイベントリスナーを設定する
    inputs.forEach(function(input, index) {
      input.addEventListener('input', function() {
        // 現在のinputの値の長さを取得
        let currentValueLength = input.value.length;

        // 現在のinputが最初のinputの場合、値の長さが3桁に達したら次のinputにフォーカスを移動
        if (index === 0 && currentValueLength === 3) {
          // 次のinputが存在するかを確認してからフォーカスを移動
          if (index < inputs.length - 1) {
            inputs[index + 1].focus();
          }
        }
        // 現在のinputが2つ目または3つ目のinputの場合、値の長さが4桁に達したら次のinputにフォーカスを移動
        else if (index > 0 && currentValueLength === 4) {
          // 次のinputが存在するかを確認してからフォーカスを移動
          if (index < inputs.length - 1) {
            inputs[index + 1].focus();
          }
        }
      });
    });
    }
  });


  document.addEventListener('DOMContentLoaded', function() {
    if(document.getElementsByClassName('c-tableData__main')) {
    let mainElements = document.getElementsByClassName('c-tableData__main');

    // ランダムな4文字のひらがなを生成する関数
    function generateRandomHiragana() {
      let characters = 'あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん';
      let result = '';
      for (let i = 0; i < 4; i++) {
        let randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    }

    // c-tableData__main のテキストをランダムなひらがなの4文字に変更する
    for (let i = 0; i < mainElements.length; i++) {
      let mainElement = mainElements[i];
      mainElement.textContent = generateRandomHiragana();
    }
    }
  });


  document.addEventListener('DOMContentLoaded', function() {
    if(document.getElementById('size-select')) {
      const categorySelect = document.getElementById('size-select');
      const subCategorySelect = document.getElementById('design-select');
      const itemList = document.getElementById('design-list').getElementsByTagName('li');

      categorySelect.addEventListener('change', filterItems);
      subCategorySelect.addEventListener('change', filterItems);

      function filterItems() {
        const selectedCategory = categorySelect.value;
        const selectedSubCategory = subCategorySelect.value;

        // アイテムを絞り込む
        for (let i = 0; i < itemList.length; i++) {
          const item = itemList[i];
          const itemCategory = item.getAttribute('size-category');
          const itemSubCategory = item.getAttribute('design-category');

          if (
            (selectedCategory === '' || selectedCategory === itemCategory) &&
            (selectedSubCategory === '' || selectedSubCategory === itemSubCategory)
          ) {
            item.style.display = 'block'; // 表示する
          } else {
            item.style.display = 'none'; // 非表示にする
          }
        }
      }
    }

    if(document.getElementById('color-select')) {
      const colorSelect = document.getElementById('color-select');
      const colorLists = document.getElementById('color-list').getElementsByTagName('li');

      colorSelect.addEventListener('change', filterColor);

      function filterColor() {
        const colorCategory = colorSelect.value;

        // アイテムを絞り込む
        for (let i = 0; i < colorLists.length; i++) {
          const colorList = colorLists[i];
          const itemCategory = colorList.getAttribute('color-category');

          if (
            (colorCategory === '' || colorCategory === itemCategory)
          ) {
            colorList.style.display = 'block'; // 表示する
          } else {
            colorList.style.display = 'none'; // 非表示にする
          }
        }
      }
    }

    if(document.getElementById('boxColor-select')) {
      const colorSelect = document.getElementById('boxColor-select');
      const colorLists = document.getElementById('boxColor-list').getElementsByTagName('li');

      colorSelect.addEventListener('change', filterColor);

      function filterColor() {
        const colorCategory = colorSelect.value;

        // アイテムを絞り込む
        for (let i = 0; i < colorLists.length; i++) {
          const colorList = colorLists[i];
          const itemCategory = colorList.getAttribute('boxColor-category');

          if (
            (colorCategory === '' || colorCategory === itemCategory)
          ) {
            colorList.style.display = 'block'; // 表示する
          } else {
            colorList.style.display = 'none'; // 非表示にする
          }
        }
      }
    }
  });
