// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/***********************************************************************************************************************
 * JS
 ********************************************************************************************************************* */
require("javascripts/home/swiper")
require("javascripts/home/yubinbango")
require("javascripts/home/app")
require("javascripts/home/top")

/***********************************************************************************************************************
 * SCSS
 ********************************************************************************************************************* */
import '../stylesheets/home'
